
<template>
  <div v-bind:class="{

    'color-orange-main': color === 'orange-main',
    'color-orange-light': color === 'orange-light',
    'color-dark': color === 'dark',

  }">
    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.06885 1.27588L9.99993 10.207" v-bind:stroke="color" stroke-width="1.78622" stroke-linecap="round"/>
      <path d="M10 1.27588L1.06892 10.207" v-bind:stroke="color" stroke-width="1.78622" stroke-linecap="round"/>
    </svg>


  </div>
</template>
<script>
export default {
  name: 'HeartIcon',
  props: {
    color: {
      type: String,
      default: '#FF4242'
    }
  },

}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";
.color-orange-main  {
  svg {
   color: $color-orange-main;
  }
}

</style>