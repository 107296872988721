<template>
    <div v-if="layout === 'list'">
        <div class="row item-grid">
            <div class="col-md-4"><div class="item item-lg"></div></div>
            <div class="col-md-4"></div>
            <div class="col-md-4"><div class="item item-lg"></div></div>
            <div class="col-md-12 mt-5"><div class="item"></div></div>
        </div>
        <div class="row mt-5 item-grid-list" v-for="i in [0,1,2,3,4,5]">
            <div class="col-md-1"><div class="item"></div></div>
            <div class="col-md-6"><div class="item"></div></div>
            <div class="col-md-2"><div class="item"></div></div>
            <div class="col-md-1"><div class="item"></div></div>
            <div class="col-md-2"><div class="item"></div></div>
        </div>
    </div>

    <div v-if="layout === 'product'">
        <div class="row">
            <div class="col-md-8">
                <div class="col-md-6 mb-5"><div class="item item-lg"></div></div>
                <div class="col-md-12 my-5"><div class="item item-lg-l"></div></div>
                <div class="col-md-12 my-5"><div class="item item-lg-xl"></div></div>
                <div class="col-md-12 mt-5"><div class="item item-lg-xl"></div></div>
            </div>
            <div class="col-md-4">
                <div class="col-md-12 mb-3 mb-3"><div class="item item-high-box"></div></div>
                <div class="col-md-12 mb-3"><div class="item item-high-box"></div></div>
            </div>
        </div>
    </div>

  <div v-if="layout === 'blog'">
    <div class="row">
      <div class="col-md-2">
        <div class="item item-lg mt-2"></div>
        <div class="item item-lg mt-2"></div>
        <div class="item item-lg mt-2"></div>
        <div class="item item-lg mt-2"></div>
      </div>
      <div class="col-md-6">
        <div v-for="item in [1,2,3]" class="mb-5">
          <div class="item item-high-box mt-2"></div>
          <div class="col-md-8"><div class="item item-lg mt-2"></div></div>
          <div class="col-md-12"><div class="item item mt-2"></div></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="item item-lg-xl mb-4"></div>
        <div class="item item-lg-xl mb-4"></div>
        <div class="item item-lg-xl mb-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'PagePreloader',
    props: {
        layout: {
            type: String,
            default: 'list'
        }
    },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.item {
    width: 100%;
    height: 20px;
    border-radius: 5px;
    background: linear-gradient(90deg, #eee, #f9f9f9, #eee);
    animation: leftToRight 1.5s infinite reverse;
    background-size: 200%;
}

.item-lg {
    height: 35px;
}
.item-lg-l {
    height: 70px;
}
.item-lg-xl {
    height: 150px;
}
.item-high-box {
    height: 400px;
}

@keyframes leftToRight {
    0% {
        background-position: -100% 0;
    }

    100% {
        background-position: 100% 0;
    }
}
</style>